
import { computed, defineComponent, PropType } from 'vue';
import { useRouter } from 'vue-router';
import { cloneDeep } from 'lodash-es';
import BlogEmpty from '@/assets/blog/blogger-empty.png';
import { IBrandFindSubscribeByConditionBrandRespListItem } from '@/modules/miaokuan-manage/sub/api/type.d';
import { $filters } from '@/core/plugins/filter';
import { BRAND_CHANNEL_TYPE_LIST } from '@/modules/miaokuan-manage/brand/constant';
import { IBrandSubItem } from '@/modules/miaokuan-manage/sub/type.d';

export default defineComponent({
  emits: [],
  components: { },
  props: {
    data: {
      type: Object as PropType<IBrandSubItem>,
      default: () => ({}),
      require: true,
    },
    moreThan4: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();
    const imgListsFront3 = computed(() => {
      const list = cloneDeep(props.data?.brandRespList) || [];
      return list.length >= 4 ? list.splice(0, 3) : list;
    });

    const imgListsEnd = computed(() => {
      const list = cloneDeep(props.data?.brandRespList) || [];
      return list?.length === 4 ? list[3] : { ossPictureLink: '', id: '' };
    });

    const handleBrandGoodsDtl = (row:IBrandFindSubscribeByConditionBrandRespListItem) => {
      const compName = $filters.getEnumLabel(BRAND_CHANNEL_TYPE_LIST, row.channelId);
      const newPage = router.resolve({
        name: `BrandDetail${compName}`,
        query: {
          // name: compName,
          brandName: props.data.brandName,
          id: row.id,
        },
      });
      window.open(newPage.href);
    };

    const handleBrandDtl = () => {
      const newPage = router.resolve({
        name: 'BrandDetail',
        query: {
          // channelId: row.channelId,
          brandName: props.data.brandName,
        },
      });
      window.open(newPage.href);
    };
    return {
      imgListsFront3,
      imgListsEnd,
      handleBrandGoodsDtl,
      handleBrandDtl,
      BlogEmpty,
    };
  },
});
