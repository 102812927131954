export enum SUB_NAME_ENUMS {
  /* 我的订阅 */
  BRAND = '1',
  /* 团队订阅 */
  INS = '2',
}

export const SUB_TYPE_LIST = [
  { value: SUB_NAME_ENUMS.BRAND, label: '品牌' },
  { value: SUB_NAME_ENUMS.INS, label: 'INS' },
];
