import { computed, nextTick, ref, watch } from 'vue';
import { getBrandSubList, getInsSubList } from '@/modules/miaokuan-manage/sub/api';
import { cloneDeep } from 'lodash-es';
import {
  BrandSubItem,
  ICustomBrandParams,
  ICustomParams,
  IFilterTagsItem,
  ISearchKeys,
  ISubItem,
} from '../../../type.d';
import {
  SEARCH_BASE_SETTING_ENUM,
  SORT_WORD_LIST, SUB_TYPE_ENUMS,
  SUB_TYPE_LIST,
} from '@/modules/miaokuan-manage/common/constant';
import { usePagination } from './use-pagination';
import { commonfuns } from 'cx-utils';
import { $filters } from '@/core/plugins/filter';
import { SUB_NAME_ENUMS } from '@/modules/miaokuan-manage/sub/constant';
/* 支持 列表查询 */

export default () => {
  const {
    handleScrollToPage,
    initPagination,
    pageSize,
    reqPageIndex,
    currentViewPage,
  } = usePagination();
  const total = ref(0);
  const baseParams: ICustomParams = {
    subscribeType: SUB_TYPE_ENUMS.MY_SUB,
  };
  const currentTab = ref<SUB_NAME_ENUMS>(SUB_NAME_ENUMS.BRAND);
  const params = ref<ICustomParams>(cloneDeep(baseParams));
  const finish = ref(false);
  const subList = ref<any[]>([]);
  // const formatParams = function(row: ICustomParams) => {
  //   return { ...row };
  // };
  function formatParams<T>(row: T) {
    return { ...row };
  }
  const getList = async (current: number) => {
    try {
      let res;
      if (currentTab.value === SUB_NAME_ENUMS.BRAND) {
        res = await getBrandSubList({
          ...formatParams<ICustomBrandParams>(cloneDeep(params.value)),
          pageNum: current,
          pageSize: pageSize.value,
        });
      } else if (currentTab.value === SUB_NAME_ENUMS.INS) {
        res = await getInsSubList({
          ...formatParams<ICustomParams>(cloneDeep(params.value)),
          pageNum: current,
          pageSize: pageSize.value,
        });
      }
      const newList = res?.data?.list || [];
      subList.value.push(...newList);
      total.value = Number(res?.data.total);
      if (Number(res?.data.total) <= current * pageSize.value) {
        finish.value = true;
      } else {
        finish.value = false;
      }
      return Promise.resolve();
    } catch (e) {
      console.log('获取首页列表 error', e);
      return Promise.reject();
    }
  };
  const reload = () => {
    reqPageIndex.value = 1;
    subList.value = [];
    getList(reqPageIndex.value);
  };
  // 懒加载
  const listLoading = ref<boolean>(false);
  const listDisabled = computed(() => listLoading.value || finish.value);
  const loadMore = async () => {
    listLoading.value = true;
    reqPageIndex.value += 1;
    await getList(reqPageIndex.value);
    listLoading.value = false;
  };
  // const handleChangeSort = (val: SORT_WORD_ENUM) => {
  //   sortConfig.value.prop = val;
  //   sortConfig.value.order = sortConfig.value.order === SORT_TYPE_ENUM.DOWN
  //     ? SORT_TYPE_ENUM.TOP : SORT_TYPE_ENUM.DOWN;
  //   reload();
  // };
  const handleCurrentSizeChange = async (val: number) => {
    try {
      if (listLoading.value) return;
      if (val > reqPageIndex.value) {
        listLoading.value = true;
        const reqArr = [];
        while (reqPageIndex.value < val) {
          reqPageIndex.value += 1;
          // eslint-disable-next-line no-await-in-loop
          reqArr.push(getInsSubList({
            ...formatParams(cloneDeep(params.value)),
            pageNum: reqPageIndex.value,
            pageSize: pageSize.value,
          }));
        }
        const resArr = await Promise.all(reqArr);
        const list:ISubItem[] = [];
        resArr.forEach((v, i) => {
          const { data } = v;
          list.push(...data.list);
          if (i === resArr.length - 1) {
            total.value = Number(data.total);
            if (Number(data.total) <= reqPageIndex.value * pageSize.value) {
              finish.value = true;
            } else {
              finish.value = false;
            }
          }
        });
        subList.value.push(...list);
        await nextTick();
        handleScrollToPage(val);
      } else {
        handleScrollToPage(val);
        //
      }
    } catch (error) {
      console.log('获取列表数据错误', error);
    }
    listLoading.value = false;
  };
  const isEmpty = computed(() => {
    return !subList.value.length && !listLoading.value;
  });

  const filtersTags = computed(() => {
    const tags: IFilterTagsItem[] = [];
    Object.entries(params.value).forEach((v) => {
      const [key] = v;
      const value = v[1] as any;
      switch (key) {
        case SEARCH_BASE_SETTING_ENUM.SUB_TYPE:
          if (commonfuns.isEmpty(value)) return;
          tags.push({
            label: '订阅类型',
            key,
            content: $filters.getEnumLabel(SUB_TYPE_LIST, value as string),
          });
          break;
        default:
          break;
      }
    });
    return tags;
  });
  const handleRemoveFilterItem = (
    key: ISearchKeys,
  ) => {
    switch (key) {
      case SEARCH_BASE_SETTING_ENUM.SUB_TYPE:
        params.value[key] = SUB_TYPE_ENUMS.MY_SUB;
        break;
      default:
        break;
    }
  };
  const handleReset = () => {
    params.value = cloneDeep(baseParams);
  };
  watch(() => filtersTags.value, () => {
    reload();
  });
  return {
    currentTab,
    filtersTags,
    handleRemoveFilterItem,
    handleReset,
    handleCurrentSizeChange,
    total,
    initPagination,
    pageSize,
    currentViewPage,
    sortList: SORT_WORD_LIST,
    params,
    subList,
    listLoading,
    finish,
    listDisabled,
    isEmpty,
    loadMore,
    reload,
  };
};
