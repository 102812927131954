
import { computed, defineComponent, PropType, inject, Ref } from 'vue';
import { useRouter } from 'vue-router';
import { cloneDeep } from 'lodash-es';
import { ICtInsSearchBloggerPostListItem } from '@/modules/miaokuan-manage/blogger/api/type.d';
import BlogEmpty from '@/assets/blog/blogger-empty.png';

export default defineComponent({
  emits: [],
  components: { },
  props: {
    data: {
      type: Array as PropType<ICtInsSearchBloggerPostListItem[]>,
      default: () => ([]),
      require: true,
    },
    moreThan4: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();
    const poId:Ref<string> = inject('poId') as Ref;
    const imgListsFront3 = computed(() => {
      const list = cloneDeep(props.data);
      return list.length >= 4 ? list.splice(0, 3) : list;
    });

    const imgListsEnd = computed(() => {
      const list = cloneDeep(props.data);
      return list.length === 4 ? list[3] : { ossPictureLink: '', postId: '' };
    });

    const handleMiaoPinDtl = (row:ICtInsSearchBloggerPostListItem) => {
      const newPage = router.resolve({
        name: 'MiaoPinDetail',
        query: {
          postId: row.postId,
        },
      });
      window.open(newPage.href);
    };

    const handleBloggerDtl = (row:ICtInsSearchBloggerPostListItem) => {
      const newPage = router.resolve({
        name: 'BloggerDetail',
        query: {
          postId: row.postId,
          poId: poId.value,
        },
      });
      window.open(newPage.href);
    };
    return {
      imgListsFront3,
      imgListsEnd,
      handleMiaoPinDtl,
      handleBloggerDtl,
      BlogEmpty,
    };
  },
});
