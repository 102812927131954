
import { defineComponent, PropType, provide, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useMathFormat } from '@/modules/miaokuan-manage/common/composables/use-math-format';
import { SUB_STATUS_ENUMS, SUB_TYPE_ENUMS } from '@/modules/miaokuan-manage/common/constant';
// import CancelSub from '@/modules/miaokuan-manage/components/cancel-sub/index.vue';
import ImgList from './img-list/index.vue';
import { ISubItem } from '@/modules/miaokuan-manage/sub/type.d';
import { trackingClick } from '@/utils/tracking';

export default defineComponent({
  emits: ['sub', 'open-cancel', 'sub-toggle'],
  components: { ImgList },
  props: {
    data: {
      type: Object as PropType<ISubItem>,
      default: () => ({}),
    },
    subType: {
      type: String as PropType<SUB_TYPE_ENUMS>,
      default: SUB_TYPE_ENUMS.MY_SUB,
    },
  },
  setup(props, { emit }) {
    provide('poId', ref(props.data.poId));
    const { intValidator } = useMathFormat();
    const router = useRouter();

    const status = ref<SUB_STATUS_ENUMS>(SUB_STATUS_ENUMS.SUB);

    const uploadLog = (eventName: string) => {
      trackingClick({
        eventName,
        targetId: props.data.poId,
      });
    };

    // 订阅｜取消订阅
    const handleSubToggle = () => {
      uploadLog('订阅');
      emit('sub-toggle', SUB_STATUS_ENUMS.SUB, props.data);
    };
    const openCancelSub = () => {
      uploadLog('取消订阅');
      emit('open-cancel', props.data);
    };

    const imgList = props.data.postList.map(item => item.ossPictureLink);

    const viewBloggerDtl = (poId:string) => {
      trackingClick({
        eventName: '查看博主详情',
        targetId: poId,
      });
      const newPage = router.resolve({
        name: 'BloggerDetail',
        query: { poId },
      });
      window.open(newPage.href);
    };

    return {
      intValidator,
      SUB_STATUS_ENUMS,
      SUB_TYPE_ENUMS,
      status,
      openCancelSub,
      handleSubToggle,
      imgList,
      viewBloggerDtl,
    };
  },
});
