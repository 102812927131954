import { SEARCH_BASE_SETTING_ENUM, SUB_TYPE_LIST } from '@/modules/miaokuan-manage/common/constant';
import type{ ISearchConfigItem, ICustomParams } from '../../../type.d';
import { Ref, computed } from 'vue';

export const useSearchConfig = (
  params: Ref<ICustomParams>,
) => {
  const searchConfig = computed(() => {
    const baseConfig: ISearchConfigItem[] = [
      {
        key: SEARCH_BASE_SETTING_ENUM.SUB_TYPE,
        render: () => {
          return (
            <custom-select
              label="订阅类型"
              v-model={params.value.subscribeType}
            >
              {SUB_TYPE_LIST.map(v => (
                <el-option value={v.value} label={v.label}/>
              ))}
            </custom-select>
          );
        },
      },
    ];
    return baseConfig;
  });
  return {
    searchConfig,
  };
};
