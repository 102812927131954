
import { defineComponent, ref } from 'vue';

import Tabs from '@/components/tabs/index';
import { SUB_NAME_ENUMS, SUB_TYPE_LIST } from '@/modules/miaokuan-manage/sub/constant';

export default defineComponent({
  components: {
    Tabs,
  },

  props: {
    // keyword: {
    //   type: String,
    //   default: '',
    // },
  },

  emits: ['change'],

  setup(_, { emit }) {
    const tabList = ref(SUB_TYPE_LIST);
    const currentTab = ref<SUB_NAME_ENUMS>(SUB_NAME_ENUMS.BRAND);

    // 切换 Tab
    const handleTab = () => {
      emit('change', currentTab.value);
    };

    // const handleSearch = () => {
    //   emit('search');
    // };

    return {
      tabList,
      currentTab,
      handleTab,
    };
  },

  render() {
    return (
      <el-row class="tab-bar" justify="space-between" align="middle">
        <el-col span={24}>
          <tabs
            lineHeight={56}
            fontSize={16}
            border={false}
            config={this.tabList}
            v-model={this.currentTab}
            onChange={this.handleTab}
          />
        </el-col>
      </el-row>
    );
  },
});
