
import { defineComponent, ref, nextTick, onMounted, reactive } from 'vue';
// import { useRouter } from 'vue-router';
// import IconClose from '@/assets/images/icon_tags_close.png';
import { processRouterQueryParams } from '@/utils/';
import useSubList from './composables/use-sub-list';
import { useSearchConfig } from './composables/use-search-config';
import { getDesignTaskOptions } from '@/modules/task-manage/design-task/api';
import type { IDyProductImageSelectionReq, IMkSubscribeReq } from '@/modules/miaokuan-manage/sub/api/type.d';
import type { IGetDesignTaskOptionsRes } from '@/modules/task-manage/design-task/api/types';
import { SORT_TYPE_ENUM, SUB_STATUS_ENUMS, SUB_TYPE_ENUMS } from '@/modules/miaokuan-manage/common/constant';
import { postBrandSubscribe, postInsSubscribe } from '@/modules/miaokuan-manage/sub/api';
// import slsLogger from '@/core/plugins/slsLogger/SLSLogger';
import { ElMessage } from 'element-plus';
import SelectImgDialog from '../../../common/components/select-img-dialog/index.vue';
import CancelSub from '@/modules/miaokuan-manage/common/components/cancel-sub/index.vue';
import InsSubItem from '../../../common/components/blog-item/index.vue';
// import BrandSubItem from './components/brand-item/index.vue';
import BrandSubItem from '@/modules/miaokuan-manage/common/components/brand-item/index.vue';
import TabBar from './components/tab-bar/index.vue';
// import { ISubItem } from '../../type.d';
import { trackingClick } from '@/utils/tracking';
import { SUB_NAME_ENUMS } from '@/modules/miaokuan-manage/sub/constant';
import { IBrandSubscribeReq } from '@/modules/miaokuan-manage/sub/api/type.d';

export default defineComponent({
  components: {
    InsSubItem,
    BrandSubItem,
    SelectImgDialog,
    TabBar,
    CancelSub,
  },
  setup() {
    // const router = useRouter();
    const goodsEl = ref<null|HTMLElement>(null);
    const tabEl = ref(null);

    const cancelTarget = reactive<{rowId: string;}>({ rowId: '' });

    // 列表查询
    const {
      currentTab,
      filtersTags,
      handleRemoveFilterItem,
      handleCurrentSizeChange,
      params,
      subList,
      reload,
      listLoading,
      finish,
      listDisabled,
      loadMore,
      isEmpty,
      sortList,
      initPagination,
      currentViewPage,
      pageSize,
      total,
    } = useSubList();

    // 列表查询
    const handleSearch = async (subName:SUB_NAME_ENUMS) => {
      currentTab.value = subName;
      try {
        reload();
      } catch (error) {
        console.log(error);
      }
    };
    // 字典和搜索项el
    const { searchConfig } = useSearchConfig(params);

    // 选图-设计任务
    const designTaskOptions = ref<IGetDesignTaskOptionsRes[]>([]);
    const getDesignOptions = async () => {
      try {
        const { data } = await getDesignTaskOptions();
        designTaskOptions.value = data;
      } catch (error) {
        console.log(error);
      }
    };
    getDesignOptions();

    // 选图｜快速选图
    const selectImgConfig = ref<{data:IDyProductImageSelectionReq;visible:boolean;}>(
      {
        data: { id: '', spu: '', channelId: '', categoryClass: '' },
        visible: false,
      },
    );

    const init = async () => {
      await nextTick();
      // url 获取请求参数
      const urlParams = processRouterQueryParams(params.value);
      urlParams.pageNum = 0;
      initPagination(goodsEl.value!);
      params.value = urlParams;
    };

    // 订阅
    const handleSub = async () => {
      if (currentTab.value === SUB_NAME_ENUMS.BRAND) {
        // 品牌
        const param:IBrandSubscribeReq = {
          brandId: cancelTarget.rowId,
          isSubscribe: SUB_STATUS_ENUMS.SUB,
        };
        await postBrandSubscribe(param);
        ElMessage.success('订阅成功');
        subList.value.forEach((item) => {
          if (item.brandId === cancelTarget.rowId) {
            item.alreadySubscribe = true;
          }
        });
      } else if (currentTab.value === SUB_NAME_ENUMS.INS) {
        // INS
        const param:IMkSubscribeReq = {
          poId: cancelTarget.rowId,
          isSubscribe: SUB_STATUS_ENUMS.SUB,
        };
        await postInsSubscribe(param);
        ElMessage.success('订阅成功');
        subList.value.forEach((item) => {
          if (item.poId === cancelTarget.rowId) {
            item.alreadySubscribe = true;
          }
        });
      }
    };

    // 取消订阅
    const cancelSubConfig = ref<{data:any;visible:boolean;}>(
      { visible: false, data: [] },
    );
    const openCancelSub = (id:string) => {
      cancelSubConfig.value.visible = true;
      cancelTarget.rowId = id;
    };
    const handleSubCancel = async () => {
      trackingClick({
        eventName: '取消订阅弹窗-确定',
        targetId: cancelTarget.rowId,
      });
      if (currentTab.value === SUB_NAME_ENUMS.BRAND) {
        // 品牌
        const param:IBrandSubscribeReq = {
          brandId: cancelTarget.rowId,
          isSubscribe: SUB_STATUS_ENUMS.CANCEL,
        };
        await postBrandSubscribe(param);
        ElMessage.success('取消订阅成功');
        subList.value.forEach((item) => {
          if (item.brandId === cancelTarget.rowId) {
            item.alreadySubscribe = false;
          }
        });
      } else if (currentTab.value === SUB_NAME_ENUMS.INS) {
        // INS
        const param:IMkSubscribeReq = {
          poId: cancelTarget.rowId,
          isSubscribe: SUB_STATUS_ENUMS.CANCEL,
        };
        await postInsSubscribe(param);
        ElMessage.success('取消订阅成功');
        subList.value.forEach((item) => {
          if (item.poId === cancelTarget.rowId) {
            item.alreadySubscribe = false;
          }
        });
      }
      cancelSubConfig.value.visible = false;
    };
    const handleTabChange = (tab:SUB_NAME_ENUMS) => {
      params.value.subscribeType = SUB_TYPE_ENUMS.MY_SUB;
      handleSearch(tab);
    };
    onMounted(() => {
      init();
    });

    return {
      tabEl,
      currentTab,
      filtersTags,
      handleRemoveFilterItem,
      handleCurrentSizeChange,
      total,
      currentViewPage,
      pageSize,
      goodsEl,
      handleSearch,
      sortList,
      searchConfig,
      designTaskOptions,
      selectImgConfig,
      subList,
      listLoading,
      finish,
      listDisabled,
      loadMore,
      isEmpty,
      params,
      useSearchConfig,
      SORT_TYPE_ENUM,
      cancelSubConfig,
      handleSub,
      openCancelSub,
      handleSubCancel,
      handleTabChange,
    };
  },
  render() {
    return (
      <div>
        <main class="container">
          <tab-bar ref="tabEl" onChange={this.handleTabChange}/>
          <div class="conditions-container">
            <div
              class="conditions-container_filters"
            >
              <div class="conditions-container_filters_label">筛选：</div>
              <div class="ul">
                {this.searchConfig.map(v => v.render())}
              </div>
            </div>
          </div>
          <section>
            <div
              class="goods-container"
              ref="goodsEl"
              v-infinite-scroll={this.loadMore}
              infinite-scroll-distance={50}
              infinite-scroll-disabled={this.listDisabled}
              infinite-scroll-immediate={false}
            >
              {this.subList.map((v, i) => (
                this.currentTab === SUB_NAME_ENUMS.INS
                  ? (<ins-sub-item
                  key={i}
                  data={v}
                  onSubToggle={this.handleSub}
                  onOpenCancel={({ poId = '' }) => this.openCancelSub(poId)}
                  subType={this.params.subscribeType}
                />)
                  : (<brand-sub-item
                    key={i}
                    data={v}
                    onSubToggle={this.handleSub}
                    onOpenCancel={({ brandId = '' }) => this.openCancelSub(brandId)}
                    subType={this.params.subscribeType}
                  />)
              ))}
            </div>
            {this.isEmpty ? (
              <empty />
            ) : (
              <div class="load-other">
                {this.listLoading && (
                  <p><i class="el-icon-loading"></i> 加载中...</p>
                )}
                {this.finish && (
                  <p>已经到底了</p>
                )}
              </div>
            )}
            <list-pagination
              total={this.total}
              current={this.currentViewPage}
              pageSize={this.pageSize}
              onCurrentChange={this.handleCurrentSizeChange}
            />
          </section>
        </main>
        <select-img-dialog
          v-model={[this.selectImgConfig.visible, 'visible']}
          data={this.selectImgConfig.data}
          options={this.designTaskOptions}
        />
        <cancel-sub
          v-model={[this.cancelSubConfig.visible, 'visible']}
          onSubCancel={this.handleSubCancel}
        />
      </div>
    );
  },
});
